export const PIN_QUERY = {
  staff: {
    model: 'User',
    query: {
      fields: ['id', 'full_name', 'image'],
      with: [
        'qr:id,staff_id,staff_type,code',
        'training:user_id,badge_number,badge_type,badge_expiry,sia_badge,badge_image',
      ],
    },
  },
  contractor_staff: {
    model: 'ContractorStaff',
    query: {
      fields: [
        'id',
        'contractor_id',
        'first_name',
        'last_name',
        'badge_number',
        'badge_type',
        'badge_expiry',
        'badge_image',
        'image',
      ],
      with: [
        'qr:id,staff_id,staff_type,code',
        'contractor:id,name',
      ],
    },
  },
  qr: {
    model: 'StaffQr',
    query: {
      fields: ['id', 'code', 'staff_id', 'staff_type'],
      with: ['staff'],
      first: true,
    },
  },
};
