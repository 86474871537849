import { Injectable, inject } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';
import Pusher, { Options } from 'pusher-js';
import { StorageService } from 'storage';
import {
  ChannelAuthorizationCallback,
  ChannelAuthorizationData,
  ChannelAuthorizationRequestParams,
} from 'pusher-js/types/src/core/auth/options';
import env from 'env';
import {
  SubscribedChannel,
  WebsocketEvent,
  SubscribedChannelData,
  QueryEvent,
  QueryEventType,
  PusherEvent,
  MutationEventType,
  MutationEvent,
  MutationData,
  PusherErrorResponse,
  ControllerQuery,
} from './websocket.type';
import { AuthService } from 'auth';
import { NotificationService } from 'notification';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private pusher!: Pusher;
  public channels: SubscribedChannel = {};
  public event: Subject<WebsocketEvent> = new Subject();
  public queryCount = 0;
  public mutationCount = 0;

  public notify = inject(NotificationService);
  public storage = inject(StorageService);
  public auth = inject(AuthService);

  init() {
    if (this.pusher) return;

    this.pusher = new Pusher('local', this.getPusherConfig());

    this.pusher.connection.bind('connected', () => {
      console.log('Websocket connected');
    });

    this.pusher.connection.bind('disconnected', () => {
      console.log('Websocket disconnected');
    });

    this.pusher.connection.bind(
      'error',
      (event: { data: PusherErrorResponse }) => {
        if (event.data.code === 1006) {
          //this.notify.error('Websocket disconnected');
          return;
        }

        this.checkAuthentication(event.data);
      },
    );
  }

  checkAuthentication(data?: PusherErrorResponse) {
    if (data?.message === 'Invalid Signature') {
      this.auth.logout();
    }
  }

  listen<T>(
    channelName: string,
    queries?: SubscribedChannelData,
    events?: string[],
  ): Observable<QueryEvent<T>> {
    if (!this.pusher) this.init();

    const channel = this.getChannel(channelName);

    if (!this.channels[channel]) {
      this.channels[channel] = {
        name: channel,
        data: new Subject(),
        queries,
      };

      this.pusher
        .subscribe(channel)
        .bind_global((event: string, data: T) => {
          if (
            !event.startsWith('pusher:') &&
            (!events?.length || events.includes(event))
          ) {
            this.channels[channel].data.next({
              event: event as QueryEventType,
              data,
            });
          }
        })
        .bind(PusherEvent.SUBSCRIPTION_ERROR, (error: unknown) => {
          console.log(PusherEvent.SUBSCRIPTION_ERROR, error, channel);
          this.deleteChannel(channel);
        });
    } else {
      this.channels[channel].queries = queries;

      if (queries) {
        this.query<T>(queries).subscribe((event) => {
          this.channels[channel].data.next(event);
        });
      }
    }

    return this.channels[channel].data.pipe(
      filter((e) => !!e?.event),
    ) as Observable<QueryEvent<T>>;
  }

  query<T>(queries: SubscribedChannelData): Observable<QueryEvent<T>> {
    if (!this.pusher) this.init();

    this.queryCount++;

    const channel = this.getQueryChannel(this.queryCount.toString());

    this.channels[channel] = {
      name: channel,
      data: new Subject(),
      hasLazyQuery: this.hasLazyQuery(queries),
      queries,
    };

    this.pusher
      .subscribe(channel)
      .bind_global((event: QueryEventType, data: T) => {
        this.channels[channel].data.next({
          event,
          data,
        });

        if (
          event === PusherEvent.ERROR ||
          !this.channels[channel].hasLazyQuery
        ) {
          this.deleteChannel(channel);
          return;
        }

        if (this.channels[channel].hasLazyQuery) {
          this.channels[channel].hasLazyQuery = false;
        }
      });

    return this.channels[channel].data.pipe(
      filter((e) => !!e?.event),
    ) as Observable<QueryEvent<T>>;
  }

  controllerQuery<T>(queries: ControllerQuery): Observable<QueryEvent<T>> {
    if (!this.pusher) this.init();

    this.queryCount++;

    const channel = this.getQueryChannel(this.queryCount.toString());

    this.channels[channel] = {
      name: channel,
      data: new Subject(),
      queries,
    };

    this.pusher
      .subscribe(channel)
      .bind_global((event: QueryEventType, data: T) => {
        this.channels[channel].data.next({
          event,
          data,
        });

        if (
          event === PusherEvent.ERROR ||
          !this.channels[channel].hasLazyQuery
        ) {
          this.deleteChannel(channel);
          return;
        }
      });

    return this.channels[channel].data.pipe(
      filter((e) => !!e?.event),
    ) as Observable<QueryEvent<T>>;
  }

  mutation(mutation: MutationData): Observable<MutationEvent> {
    if (!this.pusher) this.init();

    this.mutationCount++;

    const channel = this.getMutationChannel(this.mutationCount.toString());

    this.channels[channel] = {
      name: channel,
      data: new Subject(),
      queries: mutation,
    };

    this.pusher
      .subscribe(channel)
      .bind_global((event: MutationEventType, data: unknown) => {
        this.channels[channel].data.next({
          event,
          data,
        });

        this.deleteChannel(channel);
      });

    return this.channels[channel].data.pipe(
      filter((e) => !!e?.event),
    ) as Observable<MutationEvent>;
  }

  push(channelName: string, event: string, data: unknown) {
    this.pusher.send_event(event, data, this.getChannel(channelName));
  }

  unsubscribe(channelName: string) {
    const channel = this.getChannel(channelName);

    this.pusher.unsubscribe(channel);
    this.deleteChannel(channel);
  }

  deleteChannel(channel: string) {
    if (!this.channels[channel]) return;

    this.channels[channel].data.next(undefined);
    this.channels[channel].data.complete();

    delete this.channels[channel];
  }

  public getChannelQueries(channelName: string) {
    return this.channels[channelName]?.queries;
  }

  public getChannel(channelName: string) {
    return `private-${channelName}`.toLowerCase();
  }

  public getQueryChannel(suffix: string) {
    return `private-query.${suffix}`.toLowerCase();
  }

  public getMutationChannel(suffix: string) {
    return `private-mutation.${suffix}`.toLowerCase();
  }

  public hasLazyQuery(queries: SubscribedChannelData) {
    return Object.values(queries).some((query) => query?.lazy);
  }

  private getPusherConfig() {
    const { websocketConfig } = env;

    return {
      ...websocketConfig,
      channelAuthorization: {
        ...websocketConfig.channelAuthorization,
        customHandler: this.channelAuthorizationHandler.bind(this),
      },
    } as Options;
  }

  private async channelAuthorizationHandler(
    params: ChannelAuthorizationRequestParams,
    callback: ChannelAuthorizationCallback,
  ) {
    return callback(null, {
      auth: this.storage.get('token'),
      channel_data: this.getChannelQueries(params.channelName),
    } as ChannelAuthorizationData);
  }
}
