/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject } from 'rxjs';

export interface WebsocketEvent {
  channel: string;
  event: string;
  data?: unknown;
}

export interface Query {
  fields: string[];
  with?: WithInput[];
  withCount?: WithInput[];
  withSum?: WithSumInput[];
  join?: string[][];
  lazy?: QueryMultiple | QueryUnique;
}

export interface QueryUnique extends Query {
  id: number | string;
  where?: never;
  limit?: never;
  skip?: never;
  orderBy?: never;
  groupBy?: never;
  has?: never;
  first?: never;
}

export interface QueryMultiple extends Query {
  where?: GroupWhereInput | WhereInput;
  whereHas?: WhereHasInput;
  whereDoesntHave?: WhereHasInput;
  orderBy?: { field: string; type: 'asc' | 'desc' };
  groupBy?: string;
  limit?: number;
  skip?: number;
  has?: string[];
  doesntHave?: string[];
  having?: (string | number)[];
  distinct?: boolean;
  first?: boolean;
  count?: boolean;
}

export interface ControllerQuery {
  [controller: string]: {
    method: string;
    data: any;
  }
}

export interface SubscribedChannelData {
  [model: string]: QueryMultiple | QueryUnique;
}

export interface ControllerData {
  method: string;
  data: any;
  broadcastTo?: string;
  broadcastData?: any;
}

export interface MutationData {
  [controller: string]: ControllerData;
}

export interface SubscribedChannel {
  [channel: string]: {
    name: string;
    queries?: SubscribedChannelData | MutationData;
    data: Subject<QueryEvent<any> | MutationEvent | undefined>;
    hasLazyQuery?: boolean;
  };
}

export type WhereInput = {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | number | boolean>
    | null
    | { value: string | number | boolean | null; operator: WhereOperatorInput }
    | WhereInput;
};

export type GroupWhereInput = {
  $and?: (GroupWhereInput | WhereInput)[];
  $or?: (GroupWhereInput | WhereInput)[];
};

export type WhereHasInput = {
  [relation: string]: GroupWhereInput | WhereInput;
};

export type WithConditionInput = {
  relation: string;
  queries?: Omit<Partial<QueryMultiple>, 'first' | 'limit' | 'skip'>;
  as?: string;
};

export type WithSumConditionInput = {
  relation: string;
  field: string;
  queries?: Omit<Partial<QueryMultiple>, 'first' | 'limit' | 'skip'>;
  as?: string;
};

export type WithInput = string | WithConditionInput;
export type WithSumInput = string | WithSumConditionInput;

export type QueryEventType =
  | PusherEvent.QUERY
  | PusherEvent.MUTATION
  | PusherEvent.ERROR;

export type MutationEventType = PusherEvent.MUTATION | PusherEvent.ERROR;

export interface QueryEvent<T> {
  event: QueryEventType;
  data: T & Partial<PusherErrorResponse>;
};

export interface MutationEvent {
  event: MutationEventType;
  data: any;
}

export interface MutationResponse {
  success: boolean;
  message?: string;
  data: any;
}

export enum PusherEvent {
  SUBSCRIPTION_ERROR = 'pusher:subscription_error',
  ERROR = 'pusher:error',
  QUERY = 'query',
  MUTATION = 'mutation',
}

export type PusherErrorResponse = {
  code: number;
  message: string;
};

export type WhereOperatorInput =
  | '='
  | '<'
  | '>'
  | '<='
  | '>='
  | '<>'
  | '!='
  | 'like'
  | 'not like'
  | 'between'
  | 'not between'
  | 'in'
  | 'not in'
  | 'is null'
  | 'is not null'
  | 'scope';
