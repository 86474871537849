import { signal } from '@angular/core';
import { QueryMultiple, SubscribedChannelData } from '../lib/websocket.type';

export type QueryCondition = QueryMultiple & { id: number };

export class WebsocketQuery {
  public model: string;
  public fields: string[];
  public conditions = signal<Partial<QueryCondition>>({});

  constructor(
    model: string,
    fields: string[],
    conditions?: Partial<QueryCondition>,
  ) {
    this.model = model;
    this.fields = fields;

    if (conditions) {
      this.setConditions(conditions);
    }
  }

  public clone() {
    return new WebsocketQuery(this.model, this.fields, {
      ...this.conditions(),
    });
  }

  public getQuery(): SubscribedChannelData {
    return {
      [this.model]: {
        fields: this.fields,
        ...this.conditions(),
      },
    };
  }

  public getCondition(key: keyof QueryCondition) {
    return this.conditions()[key];
  }

  public setCondition<K extends keyof QueryCondition>(
    key: K,
    value: QueryCondition[K],
  ) {
    this.conditions.update((conditions) => {
      conditions[key] = value;

      return conditions;
    });
  }

  public updateCondition<K extends keyof QueryCondition>(
    key: K,
    updateFn: (condition: QueryCondition[K]) => QueryCondition[K],
  ) {
    this.conditions.update((conditions) => {
      conditions[key] = updateFn(conditions[key] as QueryCondition[K]);

      return { ...conditions };
    });
  }

  public setConditions(conditions: Partial<QueryCondition>) {
    this.conditions.set(conditions);
  }
}
